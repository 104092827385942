import React, { createContext, useContext } from "react";
import {
  Config,
  test,
  au,
  eu,
  us,
  stagingAu,
  stagingUs,
  local,
} from "../config";

export const ConfigContext = createContext<Config>(test);

export function useConfig() {
  const config = useContext(ConfigContext);
  if (config == null) {
    throw new Error("Config context not initialized");
  }

  return config;
}

const configMatcher = (region: string) => {
  switch (region) {
    case "au.admin.getequiem.com":
      return au;
    case "eu.admin.getequiem.com":
      return eu;
    case "us.admin.getequiem.com":
      return us;
    case "au.admin.staging.getequiem.com":
      return stagingAu;
    case "us.admin.staging.getequiem.com":
      return stagingUs;
    default:
      return local;
  }
};

export const ConfigProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const region = configMatcher(window.location.host);

  return (
    <ConfigContext.Provider value={region}>{children}</ConfigContext.Provider>
  );
};
