import { FormikErrors } from "formik";
import {
  ReqMgtCafmConfigurationType,
  ReqMgtCafmProviderSettings,
} from "../../../../../generated/admin";
import { OptionType } from "../../../../../model/OptionType";
import { stringNotEmpty } from "../../../../../util/stringNotEmpty";

export interface RequestManagementConnectionFormData {
  uuid: string;
  name: string;
  cafmProviderType: OptionType<ReqMgtCafmConfigurationType>;
  endpoint: string;
  username?: string | null;
  password?: string | null;
  isPasswordSet: boolean;
  isCallbackConnectionSet: boolean;
  simproSiteId?: number | null;
  conceptEvolutionCreateMethodName?: string | null;
  conceptEvolutionUpdateMethodName?: string | null;
  maximoCreateMethodName?: string | null;
  maximoUpdateMethodName?: string | null;
  elogbooksSiteName?: string | null;
  elogbooksServiceProviderName?: string | null;
  elogbooksPriorityName?: string | null;
}

export const cafmProviderOptions: OptionType<ReqMgtCafmConfigurationType>[] = [
  {
    value: ReqMgtCafmConfigurationType.ConceptEvolution,
    label: "Concept Evolution",
  },
  { value: ReqMgtCafmConfigurationType.Elogbooks, label: "Elogbooks" },
  { value: ReqMgtCafmConfigurationType.Excell, label: "Excell" },
  { value: ReqMgtCafmConfigurationType.Facilio, label: "Facilio" },
  { value: ReqMgtCafmConfigurationType.Maximo, label: "Maximo" },
  { value: ReqMgtCafmConfigurationType.Simpro, label: "Simpro" },
  { value: ReqMgtCafmConfigurationType.NgBailey, label: "Ng Bailey" },
];

export interface NewAccessControlConnectionFormData {
  name?: string | null;
  cafmProviderType?: OptionType<ReqMgtCafmConfigurationType> | null;
  endpoint?: string | null;
  username?: string | null;
  password?: string | null;
  simproSiteId?: number | null;
  conceptEvolutionCreateMethodName?: string | null;
  conceptEvolutionUpdateMethodName?: string | null;
  maximoCreateMethodName?: string | null;
  maximoUpdateMethodName?: string | null;
  elogbooksSiteName?: string | null;
  elogbooksServiceProviderName?: string | null;
  elogbooksPriorityName?: string | null;
}

export const validateNewRequestManagementConnectionForm = (
  values:
    | NewAccessControlConnectionFormData
    | RequestManagementConnectionFormData
) => {
  const errors: FormikErrors<
    NewAccessControlConnectionFormData | RequestManagementConnectionFormData
  > = {};

  if (!stringNotEmpty(values.name)) {
    errors.name = "Required";
  }
  if (!stringNotEmpty(values.endpoint)) {
    errors.endpoint = "Required";
  }
  if (values.cafmProviderType == null) {
    errors.cafmProviderType = "Required.";
  }

  switch (values.cafmProviderType?.value) {
    case ReqMgtCafmConfigurationType.Simpro:
      if (isNaN(Number(values.simproSiteId))) {
        errors.simproSiteId = "Enter valid Simpro Site Id.";
      }
      break;
    case ReqMgtCafmConfigurationType.ConceptEvolution:
      if (!stringNotEmpty(values.conceptEvolutionCreateMethodName)) {
        errors.conceptEvolutionCreateMethodName = "Required";
      }
      if (!stringNotEmpty(values.conceptEvolutionUpdateMethodName)) {
        errors.conceptEvolutionUpdateMethodName = "Required";
      }
      break;
    case ReqMgtCafmConfigurationType.Maximo:
      if (!stringNotEmpty(values.maximoCreateMethodName)) {
        errors.maximoCreateMethodName = "Required";
      }
      if (!stringNotEmpty(values.maximoUpdateMethodName)) {
        errors.maximoUpdateMethodName = "Required";
      }
      break;
    case ReqMgtCafmConfigurationType.Elogbooks:
      if (!stringNotEmpty(values.elogbooksSiteName)) {
        errors.elogbooksSiteName = "Required";
      }
      break;
    default:
      break;
  }

  return errors;
};

export const translateProviderSettingsFormValuesToGraphQL = (
  values:
    | NewAccessControlConnectionFormData
    | RequestManagementConnectionFormData
) => {
  const settings: ReqMgtCafmProviderSettings = {};
  switch (values.cafmProviderType?.value) {
    case ReqMgtCafmConfigurationType.Simpro:
      settings.simproSiteId = Number(values.simproSiteId);
      break;
    case ReqMgtCafmConfigurationType.ConceptEvolution:
      settings.conceptEvolutionCreateMethodName =
        values.conceptEvolutionCreateMethodName;
      settings.conceptEvolutionUpdateMethodName =
        values.conceptEvolutionUpdateMethodName;
      break;
    case ReqMgtCafmConfigurationType.Maximo:
      settings.maximoCreateMethodName = values.maximoCreateMethodName;
      settings.maximoUpdateMethodName = values.maximoUpdateMethodName;
      break;
    case ReqMgtCafmConfigurationType.Elogbooks:
      settings.elogbooksSiteName = values.elogbooksSiteName;
      settings.elogbooksPriorityName = values.elogbooksPriorityName;
      settings.elogbooksServiceProviderName =
        values.elogbooksServiceProviderName;
      break;
    default:
      break;
  }

  return settings;
};
