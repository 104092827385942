import React, { useCallback } from "react";
import { Formik, FormikHelpers, FieldArray, FormikErrors } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import styled from "styled-components";
import { CardAccordion } from "../../../../../components/SingleEqAccordion";
import { TextFormGroup } from "../../fields/TextFormGroup";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";
import {
  UpdateIntegrationInput,
  useDeleteAc1IntegrationMutation,
  useUpdateAc1IntegrationMutation,
} from "../../../../../generated/admin";
import { trimmedOrUndefined } from "../../../../../util/trimmedOrUndefined";
import { stringNotEmpty } from "../../../../../util/stringNotEmpty";
import { validURL } from "../../../../../util/validURL";
import type { Building, Integration } from "./useAc1Data";
import { useAc1Data } from "./useAc1Data";
import { OptionType } from "../../../../../model/OptionType";
import { AddButton } from "../../../../../components/AddButton";
import { RemoveButton } from "../../../../../components/RemoveButton";

const ContainerMain = styled.div`
  .building-select {
    width: auto;
    min-width: 400px;
  }
  .passes {
    background: #f2f2f2;
  }
  .eq__menu,
  .eq__menu-list {
    z-index: 10;
  }
`;

export type FormValues = Omit<
  UpdateIntegrationInput,
  "uuid" | "buildingIntegrations"
> & {
  uuid: string;
  provider: string;
  buildings: Building[];
  employeeVirtualCredentialSetupEnabled: boolean;
  employeeQrCodeCredentialSetupEnabled: boolean;
  visitorQrCodeCredentialSetupEnabled: boolean;
};

interface Props {
  integration: Integration;
  siteUuid: string;
}

export const IntegrationConfiguration: React.FC<Props> = ({
  integration,
  siteUuid,
}) => {
  const { siteBuildingOptions, virtualCredentialOptionsMap, qrCodeOptions } =
    useAc1Data(siteUuid);
  const [updateMutation, { loading: updateInProgress }] =
    useUpdateAc1IntegrationMutation();
  const [deleteMutation, { loading: deleteInProgress }] =
    useDeleteAc1IntegrationMutation();
  const initialValues: FormValues = {
    ...integration,
    buildings: !!integration?.buildings?.length
      ? integration.buildings
      : [
        {
          visitorsEnabled: false,
          employeesEnabled: false,
          building: {
            value: "",
            label: "",
          },
        },
      ],
    employeeVirtualCredentialSetupEnabled:
      integration.employeeVirtualCredentialSetupUuid != null,
    employeeQrCodeCredentialSetupEnabled:
      integration.employeeQrCodeCredentialSetupUuid != null,
    visitorQrCodeCredentialSetupEnabled:
      integration.visitorQrCodeCredentialSetupUuid != null,
  };
  const virtualCredentialOptions =
    virtualCredentialOptionsMap.get(integration.uuid) ?? [];

  const handleValidate = useCallback((values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (!stringNotEmpty(values.name)) {
      errors.name = "Required.";
    }

    if (!stringNotEmpty(values.externalApiBaseUrl)) {
      errors.externalApiBaseUrl = "Required.";
    } else if (!validURL(values.externalApiBaseUrl)) {
      errors.externalApiBaseUrl =
        "Please enter a valid URL. For example: https://api.cert.origo.hidglobal.com";
    }

    if (
      stringNotEmpty(values.externalApiDataUrl) &&
      !validURL(values.externalApiDataUrl)
    ) {
      errors.externalApiDataUrl =
        "Please enter a valid URL. For example: https://api.cert.origo.hidglobal.com";
    }

    if (
      values.employeeVirtualCredentialSetupEnabled &&
      values.employeeVirtualCredentialSetupUuid == null
    ) {
      errors.employeeVirtualCredentialSetupUuid =
        "Please select a valid access pass when you enable virtual card.";
    }

    if (
      values.employeeQrCodeCredentialSetupEnabled &&
      values.employeeQrCodeCredentialSetupUuid == null
    ) {
      errors.employeeQrCodeCredentialSetupUuid =
        "Please select a valid QR code type when you enable QR code, so the QR code is generated correctly.";
    }

    if (
      values.visitorQrCodeCredentialSetupEnabled &&
      values.visitorQrCodeCredentialSetupUuid == null
    ) {
      errors.visitorQrCodeCredentialSetupUuid =
        "Please select a valid QR code type when you enable QR code, so the QR code is generated correctly.";
    }

    return errors;
  }, []);

  const handleUpdate = useCallback(
    async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        if (updateInProgress) {
          return;
        }

        const employee_setup_payload = someBuildingWithEmployees(values.buildings)
          ? {
            employeePhysicalCardEnabled: values.employeePhysicalCardEnabled,
            employeeQrCodeCredentialSetupUuid:
              values.employeeQrCodeCredentialSetupEnabled
                ? values.employeeQrCodeCredentialSetupUuid
                : null,
            employeeVirtualCredentialSetupUuid:
              values.employeeVirtualCredentialSetupEnabled
                ? values.employeeVirtualCredentialSetupUuid
                : null,
          }
          : {}

        const visitor_setup_payload = someBuildingWithVisitors(values.buildings)
          ? {
            visitorPhysicalCardEnabled: values.visitorPhysicalCardEnabled,
            visitorQrCodeCredentialSetupUuid:
              values.visitorQrCodeCredentialSetupEnabled
                ? values.visitorQrCodeCredentialSetupUuid
                : null
          }
          : {}

        const result = await updateMutation({
          variables: {
            input: {
              uuid: values.uuid,
              name: values.name.trim(),
              buildingIntegrations: values.buildings.map(
                ({ building, visitorsEnabled, employeesEnabled }) => ({
                  buildingUuid: building.value,
                  visitorsEnabled,
                  employeesEnabled,
                })
              ),
              ...employee_setup_payload,
              ...visitor_setup_payload,
              ...(stringNotEmpty(values.accessPassName) ?
                { accessPassName: values.accessPassName.trim() }
                : {}),
              externalApiBaseUrl: values.externalApiBaseUrl.trim(),
              externalApiDataUrl: trimmedOrUndefined(values.externalApiDataUrl),
              externalApiKey: trimmedOrUndefined(values.externalApiKey),
              externalApiSuffix: trimmedOrUndefined(values.externalApiSuffix),
              externalApiTokenSuffix: trimmedOrUndefined(
                values.externalApiTokenSuffix
              ),
              externalClientId: trimmedOrUndefined(values.externalClientId),
              externalClientPassword: trimmedOrUndefined(
                values.externalClientPassword
              ),
              externalClientScope: trimmedOrUndefined(
                values.externalClientScope
              ),
              externalClientSecret: trimmedOrUndefined(
                values.externalClientSecret
              ),
              externalClientUsername: trimmedOrUndefined(
                values.externalClientUsername
              ),
              connectionAndKeyPassword: trimmedOrUndefined(
                values.connectionAndKeyPassword
              ),
              connectionCertificate: stringNotEmpty(
                values.connectionCertificate
              )
                ? values.connectionCertificate
                : undefined,
              connectionKey: stringNotEmpty(values.connectionKey)
                ? values.connectionKey
                : undefined,
              config: stringNotEmpty(values.config) ? values.config : undefined,
            },
          },
          refetchQueries: ["Ac1Integrations"],
        });

        if (result.errors != null) {
          console.error(result.errors);
          if (result.errors.length === 0) {
            throw new Error("Unknown error response from server.");
          }
          throw result.errors[0];
        }

        resetForm();
        EqMessageSuccess({
          text: "Successfully updated integration.",
        });
      } catch (e: unknown) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [updateInProgress, updateMutation]
  );

  const deleteWithConfirmation = useCallback(
    async (uuid: string, name: string) => {
      const confirm = await EqDangerMessage({
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        title: "Are you sure?",
        html: `Once "${name}" is deleted, you cannot retrieve this integration.`,
      });

      if (confirm.value !== true || deleteInProgress) {
        return;
      }

      try {
        const result = await deleteMutation({
          variables: { uuid },
          refetchQueries: ["Ac1Integrations"],
        });

        if (result.errors != null) {
          console.error(result.errors);
          if (result.errors.length === 0) {
            throw new Error("Unknown error response from server.");
          }
          throw result.errors[0];
        }

        EqMessageSuccess({ text: `${name} deleted. ` });
      } catch (e: unknown) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [deleteMutation, deleteInProgress]
  );

  const getFilteredOptions = (
    selectedBuildings: Building[],
    currentIndex: number
  ) => {
    const selectedValues = selectedBuildings
      .map((b, index) => (index !== currentIndex ? b.building?.value : null))
      .filter(Boolean);
    return siteBuildingOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const someBuildingWithEmployees = (buildings: Building[]) => {
    return buildings.some(
      ({ employeesEnabled }) => employeesEnabled
    )
  }

  const someBuildingWithVisitors = (buildings: Building[]) => {
    return buildings.some(
      ({ visitorsEnabled }) => visitorsEnabled
    )
  }


  const findSelectedOption = (value: string) =>
    siteBuildingOptions.find((option) => option.value === value) || null;

  return (
    <ContainerMain className="container-main">
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpdate}
        validate={handleValidate}
        enableReinitialize
      >
        {(methods) => (
          <CardAccordion cardClassName="flex-nowrap" title={integration.name}>
            <Form>
              <Form.Group as={Row} controlId="buildings">
                <Form.Label column md="3">
                  {"Buildings"}
                </Form.Label>
                <Col md="9" lg="6">
                  <FieldArray
                    name="buildings"
                    render={(arrayHelpers) => (
                      <div>
                        {methods.values.buildings.map(
                          (buildingEntity, index) => {
                            return (
                              <Form.Group
                                as={Row}
                                controlId={`buildings-${index}`}
                                key={index}
                              >
                                <Col
                                  className="site-client-select d-flex align-items-center"
                                  style={{ gap: "16px" }}
                                >
                                  <div
                                    data-eq-test={`building-select-${index}`}
                                  >
                                    <Row className="mb-2">
                                      <Col className="pr-0">
                                        <Select<OptionType, false>
                                          classNamePrefix="eq"
                                          className="building-select"
                                          isSearchable
                                          value={findSelectedOption(
                                            buildingEntity?.building?.value
                                          )}
                                          options={getFilteredOptions(
                                            methods.values.buildings,
                                            index
                                          )}
                                          onChange={(selectedOption) => {
                                            if (!selectedOption) return;

                                            const updatedBuilding = {
                                              ...buildingEntity,
                                              building: {
                                                value: selectedOption.value,
                                                label: selectedOption.label,
                                              },
                                            };
                                            arrayHelpers.replace(
                                              index,
                                              updatedBuilding
                                            );
                                            methods.setFieldValue(
                                              `buildings[${index}]`,
                                              updatedBuilding
                                            );
                                          }}
                                          placeholder="Select..."
                                          aria-label="building-select"
                                        />
                                      </Col>
                                      <Col
                                        className="pl-1 pt-2 pb-0 pr-0"
                                        style={{ width: "30px" }}
                                      >
                                        {methods.values.buildings.length > 1 &&
                                          index <
                                          methods.values.buildings.length -
                                          1 && (
                                            <button
                                              type="button"
                                              data-eq-test={`bin-${index}`}
                                              style={{
                                                border: "none",
                                                background: "white",
                                              }}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <RemoveButton />
                                            </button>
                                          )}
                                        {index ===
                                          methods.values.buildings.length - 1 &&
                                          methods.values.buildings.length <
                                          siteBuildingOptions.length && (
                                            <button
                                              type="button"
                                              data-eq-test={`plus-${index}`}
                                              style={{
                                                border: "none",
                                                background: "white",
                                              }}
                                              onClick={() =>
                                                arrayHelpers.push({
                                                  value: "",
                                                  label: "",
                                                  visitorsEnabled: false,
                                                  employeesEnabled: false,
                                                })
                                              }
                                            >
                                              <AddButton />
                                            </button>
                                          )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="pr-0">
                                        <Form.Check
                                          name={`buildings[${index}].visitorsEnabled`}
                                          id={`buildings[${index}].visitorsEnabled-${methods.values.uuid}`}
                                          type="switch"
                                          label="Visitors access passes"
                                          data-eq-test={`visitors-enabled-${index}`}
                                          onChange={(e) => {
                                            const updatedBuilding = {
                                              ...buildingEntity,
                                              visitorsEnabled: e.target.checked,
                                            };
                                            arrayHelpers.replace(
                                              index,
                                              updatedBuilding
                                            );
                                            methods.setFieldValue(
                                              `buildings[${index}].visitorsEnabled`,
                                              e.target.checked
                                            );
                                          }}
                                          checked={
                                            buildingEntity.visitorsEnabled
                                          }
                                        />
                                      </Col>
                                      <Col>
                                        <Form.Check
                                          name={`buildings[${index}].employeesEnabled`}
                                          id={`buildings[${index}].employeesEnabled-${methods.values.uuid}`}
                                          type="switch"
                                          label="Employees access passes"
                                          data-eq-test={`employees-enabled-${index}`}
                                          onChange={(e) => {
                                            const updatedBuilding = {
                                              ...buildingEntity,
                                              employeesEnabled:
                                                e.target.checked,
                                            };
                                            arrayHelpers.replace(
                                              index,
                                              updatedBuilding
                                            );
                                            methods.setFieldValue(
                                              `buildings[${index}].employeesEnabled`,
                                              e.target.checked
                                            );
                                          }}
                                          checked={
                                            buildingEntity.employeesEnabled
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Form.Group>
                            );
                          }
                        )}
                      </div>
                    )}
                  />
                </Col>
              </Form.Group>
              {someBuildingWithEmployees(methods.values.buildings) ? (
                <Form.Group as={Row} className="passes mx-0 px-2 py-4">
                  <Form.Label column md="3" className="pt-0">
                    Employees Passes
                  </Form.Label>
                  <Col md="9" lg="6">
                    <Form.Check
                      type="switch"
                      id={`employeeVirtualCredentialSetupEnabled-${methods.values.uuid}`}
                      onChange={() => {
                        methods.setFieldValue(
                          "employeeVirtualCredentialSetupEnabled",
                          !methods.values.employeeVirtualCredentialSetupEnabled
                        );
                      }}
                      disabled={virtualCredentialOptions.length === 0}
                      checked={
                        methods.values.employeeVirtualCredentialSetupEnabled
                      }
                      label="Virtual Card"
                      className="mb-2"
                    />
                    {methods.values.employeeVirtualCredentialSetupEnabled ? (
                      <>
                        <Select<OptionType, false>
                          id={`employeeVirtualCredentialSetupUuid-${methods.values.uuid}`}
                          classNamePrefix="eq"
                          className={
                            methods.errors.employeeVirtualCredentialSetupUuid !=
                              null
                              ? "my-2 is-invalid"
                              : "my-2"
                          }
                          options={virtualCredentialOptions}
                          value={virtualCredentialOptions.find(
                            ({ value }) =>
                              value ===
                              methods.values.employeeVirtualCredentialSetupUuid
                          )}
                          onChange={(selectedOption) => {
                            if (!selectedOption) return;
                            methods.setFieldValue(
                              "employeeVirtualCredentialSetupUuid",
                              selectedOption.value
                            );
                          }}
                          placeholder="Select..."
                          aria-label="employeeVirtualCredentialSetupUuid-select"
                        />
                        {typeof methods.errors
                          .employeeVirtualCredentialSetupUuid === "string" ? (
                          <Form.Control.Feedback type="invalid">
                            {methods.errors.employeeVirtualCredentialSetupUuid}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    ) : null}
                    <Form.Check
                      type="switch"
                      id={`employeeQrCodeCredentialSetupEnabled-${methods.values.uuid}`}
                      onChange={() => {
                        methods.setFieldValue(
                          "employeeQrCodeCredentialSetupEnabled",
                          !methods.values.employeeQrCodeCredentialSetupEnabled
                        );
                      }}
                      disabled={qrCodeOptions.length === 0}
                      checked={
                        methods.values.employeeQrCodeCredentialSetupEnabled
                      }
                      label="QR Code"
                      className="mb-2"
                    />
                    {methods.values.employeeQrCodeCredentialSetupEnabled ? (
                      <>
                        <Select<OptionType, false>
                          id={`employeeQrCodeCredentialSetupUuid-${methods.values.uuid}`}
                          classNamePrefix="eq"
                          className={
                            methods.errors.employeeQrCodeCredentialSetupUuid !=
                              null
                              ? "my-2 is-invalid"
                              : "my-2"
                          }
                          options={qrCodeOptions}
                          isDisabled={qrCodeOptions.length === 0}
                          value={qrCodeOptions.find(
                            ({ value }) =>
                              value ===
                              methods.values.employeeQrCodeCredentialSetupUuid
                          )}
                          onChange={(selectedOption) => {
                            if (!selectedOption) return;
                            methods.setFieldValue(
                              "employeeQrCodeCredentialSetupUuid",
                              selectedOption.value
                            );
                          }}
                          placeholder="Select..."
                          aria-label="visitorsQrCode-select"
                        />
                        {typeof methods.errors
                          .employeeQrCodeCredentialSetupUuid === "string" ? (
                          <Form.Control.Feedback type="invalid">
                            {methods.errors.employeeQrCodeCredentialSetupUuid}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    ) : null}
                    <Form.Check
                      type="switch"
                      name="employeePhysicalCardEnabled"
                      id={`employeePhysicalCardEnabled-${methods.values.uuid}`}
                      checked={
                        methods.values.employeePhysicalCardEnabled ?? false
                      }
                      onChange={() => {
                        methods.setFieldValue(
                          "employeePhysicalCardEnabled",
                          !methods.values.employeePhysicalCardEnabled
                        );
                      }}
                      label="Physical Card"
                      className="my-2"
                    />
                  </Col>
                </Form.Group>
              ) : null}
              {someBuildingWithVisitors(methods.values.buildings) ? (
                <Form.Group as={Row} className="passes mx-0 px-2 py-4">
                  <Form.Label column md="3" className="pt-0">
                    Visitors Passes
                  </Form.Label>
                  <Col md="9" lg="6">
                    <Form.Check
                      type="switch"
                      id={`visitorQrCodeCredentialSetupEnabled-${methods.values.uuid}`}
                      onChange={() => {
                        methods.setFieldValue(
                          "visitorQrCodeCredentialSetupEnabled",
                          !methods.values.visitorQrCodeCredentialSetupEnabled
                        );
                      }}
                      disabled={qrCodeOptions.length === 0}
                      checked={
                        methods.values.visitorQrCodeCredentialSetupEnabled
                      }
                      label="QR Code"
                      className="mb-2"
                    />
                    {methods.values.visitorQrCodeCredentialSetupEnabled ? (
                      <>
                        <Select<OptionType, false>
                          id={`visitorQrCodeCredentialSetupUuid-${methods.values.uuid}`}
                          classNamePrefix="eq"
                          className={
                            methods.errors.visitorQrCodeCredentialSetupUuid !=
                              null
                              ? "my-2 is-invalid"
                              : "my-2"
                          }
                          options={qrCodeOptions}
                          isDisabled={qrCodeOptions.length === 0}
                          value={qrCodeOptions.find(
                            ({ value }) =>
                              value ===
                              methods.values.visitorQrCodeCredentialSetupUuid
                          )}
                          onChange={(selectedOption) => {
                            if (!selectedOption) return;
                            methods.setFieldValue(
                              "visitorQrCodeCredentialSetupUuid",
                              selectedOption.value
                            );
                          }}
                          placeholder="Select..."
                          aria-label="visitorsQrCode-select"
                        />
                        {typeof methods.errors
                          .visitorQrCodeCredentialSetupUuid === "string" ? (
                          <Form.Control.Feedback type="invalid">
                            {methods.errors.visitorQrCodeCredentialSetupUuid}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    ) : null}
                    <Form.Check
                      type="switch"
                      name="visitorPhysicalCardEnabled"
                      id={`visitorPhysicalCardEnabled-${methods.values.uuid}`}
                      label="Physical Card"
                      className="my-2"
                      checked={
                        methods.values.visitorPhysicalCardEnabled ?? false
                      }
                      onChange={() => {
                        methods.setFieldValue(
                          "visitorPhysicalCardEnabled",
                          !methods.values.visitorPhysicalCardEnabled
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              ) : null}
              <TextFormGroup
                title="Name"
                subText="The unique name for this integration configuration."
                name="name"
                error={methods.errors.name}
              />
              <TextFormGroup title="Provider" name="provider" disabled />
              <TextFormGroup
                title="Pass Name"
                subText="The unique name that the user will see in the mobile app."
                name="accessPassName"
                error={methods.errors.accessPassName}
              />
              <TextFormGroup
                title="Provider API Base URL"
                subText="The base URL for the provider API."
                name="externalApiBaseUrl"
                error={methods.errors.externalApiBaseUrl}
              />
              <TextFormGroup
                title="Provider API Data URL"
                name="externalApiDataUrl"
                error={methods.errors.externalApiDataUrl}
              />
              <TextFormGroup
                title="Provider API Key"
                name="externalApiKey"
                placeholder="Enter new API Key or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider API Suffix"
                name="externalApiSuffix"
              />
              <TextFormGroup
                title="Provider API Token Suffix"
                name="externalApiTokenSuffix"
              />
              <TextFormGroup
                title="Provider Client ID"
                name="externalClientId"
                placeholder="Enter new Client ID or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider Client Password"
                name="externalClientPassword"
                placeholder="Enter new Client Password or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider Client Scope"
                name="externalClientScope"
                placeholder="Enter new Client Scope or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider Client Secret"
                name="externalClientSecret"
                placeholder="Enter new Client Secret or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider Client Username"
                name="externalClientUsername"
                placeholder="Enter new Client Username or leave blank to keep existing"
              />
              <TextFormGroup
                title="Connection and Key Password"
                name="connectionAndKeyPassword"
                placeholder="Enter new Connection and Key Password or leave blank to keep existing"
              />
              <TextFormGroup
                title="Connection Certificate"
                name="connectionCertificate"
                rows={12}
                placeholder="Enter new Connection Certificate or leave blank to keep existing"
                asTextarea
              />
              <TextFormGroup
                title="Connection Key"
                name="connectionKey"
                rows={12}
                placeholder="Enter new Connection Key or leave blank to keep existing"
                asTextarea
              />
              <TextFormGroup
                title="Misc Configuration"
                subText="Any other extra configuration in JSON"
                name="config"
                rows={12}
                asTextarea
              />
              <div className="form-inline justify-content-end mt-1">
                <Button
                  name="updateIntegration"
                  variant="outline-primary"
                  size="sm"
                  className="align-self-center m-2"
                  onClick={() => methods.handleSubmit()}
                  disabled={updateInProgress || !methods.isValid}
                >
                  {updateInProgress ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Update integration"
                  )}
                </Button>
                <Button
                  name="deleteIntegration"
                  variant="outline-danger"
                  size="sm"
                  className="align-self-center"
                  onClick={() =>
                    deleteWithConfirmation(
                      methods.values.uuid,
                      methods.values.name
                    )
                  }
                  disabled={deleteInProgress}
                >
                  {deleteInProgress ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Delete integration"
                  )}
                </Button>
              </div>
            </Form>
          </CardAccordion>
        )}
      </Formik>
    </ContainerMain>
  );
};
