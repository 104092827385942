import React, { useState, useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  SiteDetailsQuery,
  SwiftConnectSdkType,
  useConnectSwiftHidsdkMutation,
  useConnectSwiftMobileSdkMutation,
} from "../../../../generated/admin";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";
import { validEmail } from "../../../../util/validEmail";
import { trimmedOrUndefined } from "../../../../util/trimmedOrUndefined";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { Password } from "./Password";
import Select from "react-select";
import { OptionType } from "../../../../model/OptionType";
import { stringIsEmpty } from "../../../../util/stringIsEmpty";

interface HIDSDK {
  applicationId: string;
  hidOrigoId: string;
}

interface MobileSDK {
  instanceId: string;
  organizationId: string;
}

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
  successCb: (
    email: string,
    connectedTs: number,
    sdk: HIDSDK | MobileSDK,
    label: string,
  ) => void;
}

interface FormValue<T = string> {
  invalid: boolean;
  value: T | null;
}

interface HIDSDKFormValue {
  applicationId: FormValue;
  hidOrigoId: FormValue;
}

interface MobileSDKFormValue {
  instanceId: FormValue;
  organizationId: FormValue;
}

interface ConnectForm {
  label: FormValue;
  email: FormValue;
  password: FormValue;
  sdk: FormValue<MobileSDKFormValue | HIDSDKFormValue>;
}

const sdkTypes = Object.values(SwiftConnectSdkType).map((value) => ({
  value,
  label: value,
}));

const defaultState: ConnectForm = {
  label: { invalid: false, value: "SwiftConnect" },
  email: { invalid: false, value: null },
  password: { invalid: false, value: null },
  sdk: {
    invalid: false,
    value: {
      applicationId: { invalid: false, value: null },
      hidOrigoId: { invalid: false, value: null },
    },
  },
};

export const SwiftconnectConnectForm: React.FC<Props> = ({
  dest,
  successCb,
}) => {
  const [hidMutation, { loading: hidLoading }] =
    useConnectSwiftHidsdkMutation();
  const [mobileMutation, { loading: mobileLoading }] =
    useConnectSwiftMobileSdkMutation();
  const [state, setState] = useState<ConnectForm>(defaultState);

  const artwork = dest.integrations?.swiftconnect.artWork;
  const connectAccount = useCallback(async () => {
    if (
      hidLoading ||
      mobileLoading ||
      stringIsEmpty(state.label.value) ||
      state.email.invalid ||
      state.password.invalid ||
      state.sdk.invalid
    ) {
      return;
    }

    try {
      const sdk = state.sdk.value;
      if (!sdk) {
        throw new Error("Please select a sdk type.");
      }
      if (!stringNotEmpty(artwork)) {
        throw new Error(
          "The Access pass artwork is missing. Please add this file and save the form before returning to add your connection.",
        );
      }

      if ("instanceId" in sdk) {
        if (!sdk.instanceId.value) {
          throw new Error("Please set the Instance UUID.");
        }
        if (!sdk.organizationId.value) {
          throw new Error("Please set the Organization UUID.");
        }
        const result = await mobileMutation({
          variables: {
            email: state.email.value ?? "",
            password: state.password.value ?? "",
            label:
              state.label.value?.trim() === defaultState.label.value
                ? undefined
                : trimmedOrUndefined(state.label.value),
            site: dest.uuid,
            instanceId: sdk.instanceId.value,
            organizationId: sdk.organizationId.value,
          },
        });

        if (result.data?.swiftconnect?.connectMobileCredentialsSDK == null) {
          throw new Error("Connection returning no data.");
        }

        if (
          result.data.swiftconnect.connectMobileCredentialsSDK.__typename ===
          "SwiftconnectAuthenticationFailure"
        ) {
          throw new Error(
            result.data.swiftconnect.connectMobileCredentialsSDK.reason,
          );
        }
        EqMessageSuccess({ text: "Successfully connected to SwiftConnect." });

        setState(defaultState);

        const info = result.data.swiftconnect.connectMobileCredentialsSDK.info;
        successCb(
          info.email,
          info.connectedTimestamp,
          info.sdk,
          info.label ?? undefined,
        );
      } else {
        if (!sdk.applicationId.value) {
          throw new Error("Please set the Application ID.");
        }
        if (!sdk.hidOrigoId.value) {
          throw new Error("Please set the HID Origo UUID.");
        }
        const result = await hidMutation({
          variables: {
            email: state.email.value ?? "",
            password: state.password.value ?? "",
            label:
              state.label.value?.trim() === defaultState.label.value
                ? undefined
                : trimmedOrUndefined(state.label.value),
            site: dest.uuid,
            applicationId: sdk.applicationId.value,
            hidOrigoId: sdk.hidOrigoId.value,
          },
        });

        if (result.data?.swiftconnect?.connectHIDSDK == null) {
          throw new Error("Connection returning no data.");
        }

        if (
          result.data.swiftconnect.connectHIDSDK.__typename ===
          "SwiftconnectAuthenticationFailure"
        ) {
          throw new Error(result.data.swiftconnect.connectHIDSDK.reason);
        }
        EqMessageSuccess({ text: "Successfully connected to SwiftConnect." });

        setState(defaultState);

        const info = result.data.swiftconnect.connectHIDSDK.info;
        successCb(
          info.email,
          info.connectedTimestamp,
          info.sdk,
          info.label ?? undefined,
        );
      }
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [
    hidLoading,
    mobileLoading,
    state.email.invalid,
    state.email.value,
    state.password.invalid,
    state.password.value,
    state.sdk.invalid,
    state.sdk.value,
    state.label.value,
    artwork,
    mobileMutation,
    dest.uuid,
    successCb,
    hidMutation,
  ]);

  const emailOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        email: { invalid: !validEmail(value), value },
      }));
    },
    [],
  );

  const applicationIdOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState(
        (pstate) =>
          ({
            ...pstate,
            sdk: {
              ...pstate.sdk,
              value: {
                ...pstate.sdk.value,
                applicationId: { invalid: !stringNotEmpty(value), value },
              },
            },
          }) as any,
      );
    },
    [],
  );

  const origoIdOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState(
        (pstate) =>
          ({
            ...pstate,
            sdk: {
              ...pstate.sdk,
              value: {
                ...pstate.sdk.value,
                hidOrigoId: { invalid: !stringNotEmpty(value), value },
              },
            },
          }) as any,
      );
    },
    [],
  );

  const instanceIdOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState(
        (pstate) =>
          ({
            ...pstate,
            sdk: {
              ...pstate.sdk,
              value: {
                ...pstate.sdk.value,
                instanceId: { invalid: !stringNotEmpty(value), value },
              },
            },
          }) as any,
      );
    },
    [],
  );

  const organizationOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState(
        (pstate) =>
          ({
            ...pstate,
            sdk: {
              ...pstate.sdk,
              value: {
                ...pstate.sdk.value,
                organizationId: { invalid: !stringNotEmpty(value), value },
              },
            },
          }) as any,
      );
    },
    [],
  );

  const sdkOnChange = useCallback((value: SwiftConnectSdkType) => {
    setState((pstate) => ({
      ...pstate,
      sdk: {
        invalid: false,
        value:
          value === SwiftConnectSdkType.SwiftConnectHidsdk
            ? {
                applicationId: { invalid: false, value: null },
                hidOrigoId: { invalid: false, value: null },
              }
            : {
                instanceId: { invalid: false, value: null },
                organizationId: { invalid: false, value: null },
              },
      },
    }));
  }, []);

  const passwordOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        password: { invalid: !stringNotEmpty(value), value },
      }));
    },
    [],
  );

  return (
    <div>
      <div className="pb-4">
        Log in with your SwiftConnect user account to connect to the
        SwiftConnect API.
      </div>
      <Form.Group className="pt-4" as={Row}>
        <Form.Label column md="3" lg="2">
          Label
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="swiftconnectLabel"
            type="text"
            isInvalid={state.label.invalid || stringIsEmpty(state.label.value)}
            disabled={hidLoading || mobileLoading}
            onChange={(e) =>
              setState((connectForm) => ({
                ...connectForm,
                label: {
                  value: e.target.value,
                  invalid: e.target.value.length > 20,
                },
              }))
            }
            defaultValue={state.label.value ?? undefined}
          />
          <Form.Text className="text-muted">
            Integration name used as label in the mobile app (up to 20
            characters long)
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            {stringIsEmpty(state.label.value)
              ? "The label is missing."
              : "Label must be 20 characters or less"}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} required>
        <Form.Label column md="3" lg="2">
          SDK type
        </Form.Label>
        <Col md="9" lg="6">
          <Select<OptionType<SwiftConnectSdkType>>
            className={`react-select`}
            classNamePrefix="eq"
            options={sdkTypes}
            defaultValue={sdkTypes[0]}
            onChange={(o) => {
              sdkOnChange(o!.value);
            }}
          />

          <Form.Text className="text-muted">
            Select the type of the SwiftConnect SDK to use with this connection
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          Email
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="swiftconnectEmail"
            type="text"
            isInvalid={state.email.invalid}
            disabled={hidLoading || mobileLoading}
            onChange={emailOnChange}
          />
          <Form.Text className="text-muted">
            Enter your SwiftConnect email address
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          Password
        </Form.Label>
        <Col md="9" lg="6">
          <Password
            name="swiftconnectPassword"
            onPasswordChange={passwordOnChange}
            loading={hidLoading || mobileLoading}
            isInvalid={state.password.invalid}
          />
          <Form.Text className="text-muted">
            Enter your SwiftConnect password
          </Form.Text>
        </Col>
      </Form.Group>
      {state.sdk.value && "applicationId" in state.sdk.value ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column md="3" lg="2">
              Application ID
            </Form.Label>
            <Col md="9" lg="6">
              <Form.Control
                name="swiftconnectApplicationId"
                type="text"
                isInvalid={state.sdk.value?.applicationId.invalid}
                disabled={hidLoading || mobileLoading}
                onChange={applicationIdOnChange}
              />
              <Form.Text className="text-muted">
                Enter the SwiftConnect Application ID. The format will be
                similar to HID-SWIFTCONNECT-TEST
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column md="3" lg="2">
              HID Origo UUID
            </Form.Label>
            <Col md="9" lg="6">
              <Form.Control
                name="swiftconnectOrigoId"
                type="text"
                isInvalid={state.sdk.value?.hidOrigoId.invalid}
                disabled={hidLoading || mobileLoading}
                onChange={origoIdOnChange}
              />
              <Form.Text className="text-muted">
                Enter the HID Origo UUID. The format will be similar to
                098d22af-2b67-411c-8a92-29269b69fda3
              </Form.Text>
            </Col>
          </Form.Group>
        </>
      ) : (
        <>
          <Form.Group as={Row}>
            <Form.Label column md="3" lg="2">
              Instance UUID
            </Form.Label>
            <Col md="9" lg="6">
              <Form.Control
                name="swiftconnectInstanceId"
                type="text"
                isInvalid={state.sdk.value?.instanceId.invalid}
                disabled={hidLoading || mobileLoading}
                onChange={instanceIdOnChange}
              />
              <Form.Text className="text-muted">
                Enter the SwiftConnect Instance UUID
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column md="3" lg="2">
              Organization UUID
            </Form.Label>
            <Col md="9" lg="6">
              <Form.Control
                name="swiftconnectOrganizationId"
                type="text"
                isInvalid={state.sdk.value?.organizationId.invalid}
                disabled={hidLoading || mobileLoading}
                onChange={organizationOnChange}
              />
              <Form.Text className="text-muted">
                Enter the SwiftConnect Organization UUID
              </Form.Text>
            </Col>
          </Form.Group>
        </>
      )}
      <div className="text-right">
        <Button
          name="connectSwiftconnect"
          variant="outline-primary"
          onClick={connectAccount}
          disabled={
            hidLoading ||
            mobileLoading ||
            state.label.invalid ||
            stringIsEmpty(state.label.value)
          }
        >
          {hidLoading || mobileLoading ? (
            <span>
              <Spinner size="sm" animation="grow" /> Loading...
            </span>
          ) : (
            "Connect account"
          )}
        </Button>
      </div>
    </div>
  );
};
