import { SocialLinkType } from "../generated/admin";
import startCase from "lodash/startCase";


export const socialLinkTypeToLabel = (linkType: SocialLinkType) => {
  switch(linkType) {
    case SocialLinkType.Linkedin:
      return "LinkedIn";
    case SocialLinkType.Tiktok:
      return "TikTok";
    case SocialLinkType.Twitter:
      return "X"

    default:
      return startCase(linkType.toLowerCase())
  }
}
