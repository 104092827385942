import React from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  Formik,
  Form as FmForm,
  FormikErrors,
  FormikValues,
  Field,
} from "formik";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { useUpdateDestinationIntegrationsMutation } from "../../../../generated/admin";
import { stringIsEmpty } from "../../../../util/stringIsEmpty";

export const ViciniteeConnectForm: React.FC<{
  siteUuid: string;
  label?: string;
  edit?: boolean;
}> = ({ siteUuid, label = "Vicinitee", edit = false }) => {
  const [mutation, { loading }] = useUpdateDestinationIntegrationsMutation({
    refetchQueries: ["SiteDetails"],
  });

  return (
    <div>
      {edit ? null : "Enable Vicinitee Access Control in the mobile app."}
      <Formik
        initialValues={{ label }}
        validate={(values) => {
          const errors: FormikErrors<FormikValues> = {};

          if (values.label.length > 20) {
            errors.label = "Label must be 20 characters or less";
          }

          if (stringIsEmpty(values.label)) {
            errors.label = "The label is missing.";
          }

          return errors;
        }}
        onSubmit={async (values) => {
          const result = await mutation({
            variables: {
              integrations: {
                destinationUuid: siteUuid,
                vicinitee: { enabled: true, label: values.label },
              },
            },
          });

          if (result.data?.updateDestinationIntegrations.success === true) {
            EqMessageSuccess({
              text: `Successfully ${
                edit ? "updated" : "enabled"
              } Vicinitee Access Control.`,
            });
          } else {
            EqMessageError({
              text:
                result.data?.updateDestinationIntegrations.message ??
                `Failed to ${
                  edit ? "update" : "enable"
                } Vicinitee Access Control`,
            });
          }
        }}
      >
        {({ isValid, errors }) => (
          <FmForm>
            <Form.Group className="pt-4" as={Row}>
              <Form.Label column md="3" lg="2">
                Label
              </Form.Label>
              <Col md="9" lg="6">
                <Field
                  as={Form.Control}
                  name="label"
                  type="text"
                  isInvalid={errors.label != null}
                />
                <Form.Text className="text-muted">
                  Integration name used as label in the mobile app (up to 20
                  characters long)
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.label}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <div className="text-right">
              <Button
                name="enable"
                type="submit"
                variant="outline-primary"
                disabled={loading || !isValid}
              >
                {loading ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : edit ? (
                  "Update"
                ) : (
                  "Enable Vicinitee"
                )}
              </Button>
            </div>
          </FmForm>
        )}
      </Formik>
    </div>
  );
};
