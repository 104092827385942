import { FormikConfig, FormikErrors } from "formik";
import {
  useUpsertDestinationMutation,
  AdminDestinationStatus,
  AdminSocialLinkInput,
  SiteListDocument,
  AdminParkingProvider,
  AdminFeatureModuleType,
  FeatureModuleType,
  AdminFeatureModulesInput,
  SiteDetailsDocument,
  useUpsertStatsProviderConfigurationMutation,
  AdminAccessControlSolution,
} from "../../../generated/admin";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import {
  EqMessageError,
  EqMessageSuccess,
  EqMessageWarning,
} from "../../message/EqMessage";
import {
  BUILDINGINFO_CATEGORY_MAX_CHAR,
  SiteConfigFormData,
} from "./SiteConfigFormData";
import { diff } from "deep-object-diff";
import { useConfig } from "../../../providers/ConfigProvider";
import { useUser } from "../../user/UserContext";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
import { toPercentageString, toRate } from "../../../util/toPercentageString";
import { validateAddress } from "../../../util/address";
import { useUpdateSwfitconnectArtworkMutation } from "../../../generated/admin";
import { validEmail } from "../../../util/validEmail";
import { trimmedOrNull } from "../../../util/trimmedOrNull";
import { SiteSettingsQueryData } from "./SiteSettingsModule";

const hexColorRegex = /^#[0-9A-F]{6}$/i;

const defaultFromEmail = "support@getequiem.com";

export const siteConfigFormValidation =
  (queryData: SiteSettingsQueryData) =>
  (formData: SiteConfigFormData): FormikErrors<SiteConfigFormData> => {
    const errors: FormikErrors<SiteConfigFormData> = {};
    if (formData.primaryColor != null && !hexColorRegex.test(formData.primaryColor)) {
      errors.primaryColor =
        "The primary colour hex format is incorrect. A hex code must be entered in the format #000000.";
    }
    if (formData.mobileColor != null && !hexColorRegex.test(formData.mobileColor)) {
      errors.mobileColor =
        "The primary colour hex format is incorrect. A hex code must be entered in the format #000000.";
    }
    if (formData.defaultIrisAuthor == null) {
      errors.defaultIrisAuthor = "Required";
    }
    if (formData.managedBy === "PORTAL") {
      const disallowedChanges = validateChanges(formData, queryData);
      return disallowedChanges.reduce((carry, item) => {
        carry[
          item
        ] = `This field cannot be updated on a site not managed by admin panel`;

        return carry;
      }, errors);
    }

    if (!validateAddress(formData.addressInput)) {
      errors.addressInput = { address: "Enter and select a valid address." };
    }

    const commission = formData.bookingModuleSettings.commissionPercentage;
    if (
      stringNotEmpty(commission) &&
      (isNaN(Number(commission)) ||
        Number(commission) < 0 ||
        Number(commission) > 100)
    ) {
      errors.bookingModuleSettings = {
        ...errors.bookingModuleSettings,
        commissionPercentage: "Commission can only be between 0 to 100.",
      };
    }

    if (stringNotEmpty(formData.email) && !validEmail(formData.email)) {
      errors.email = "Invalid contact email address.";
    }

    if (stringNotEmpty(formData.fromEmail) && !validEmail(formData.fromEmail)) {
      errors.fromEmail = "Invalid from email address.";
    }

    if (stringNotEmpty(formData.replyToEmail) && !validEmail(formData.replyToEmail)) {
      errors.replyToEmail = "Invalid reply-to email address.";
    }

    if (formData.statsProviderConfiguration != null && formData.statsProviderConfiguration.enabled === true && !stringNotEmpty(formData.statsProviderConfiguration.url)) {
      errors.statsProviderConfiguration = "You must provide a SQS URL for enabled Stats Provider Configuration";
    }

    return errors;
  };

function validateChanges(
  input: SiteConfigFormData,
  data: SiteSettingsQueryData
): Array<keyof SiteConfigFormData> {
  const changes = diff(input, transformInitialValues(data));
  const allowedNonUCMFields: Array<keyof SiteConfigFormData> = [
    "autoDeactivateUsers",
    "autoDeactivate3MonthReminder",
    "autoDeactivate6MonthReminder",
    "autoDeactivate9MonthReminder",
    "buildingInfoTitle",
    "buildingInfoCategoryNameInput",
    "buildingInfoCategories",
    "textOverAppHeroImage",
    "managedBy",
  ];
  const keys = Object.keys(changes) as unknown as Array<
    keyof SiteConfigFormData
  >;
  const disallowedChanges = keys.filter(
    (change) => !allowedNonUCMFields.includes(change)
  );
  return disallowedChanges;
}

export const featureModuleInput = (
  formValues: SiteConfigFormData["featureModules"],
  isAdmin: boolean
) => {
  if (!isAdmin) {
    // Backend is smart enough to know null means not to update the field.
    return undefined;
  }

  if (formValues == null) {
    return [];
  }

  const requestsInput: AdminFeatureModulesInput | undefined = formValues
    .requests.enabled
    ? {
        label: formValues.requests.label,
        type: AdminFeatureModuleType.Requests,
        enableMenu: formValues.requests.enableMenu,
      }
    : undefined;

  const bookingInput: AdminFeatureModulesInput | undefined = formValues.bookings
    .enabled
    ? {
        label: formValues.bookings.label,
        type: AdminFeatureModuleType.Bookings,
        enableMenu: formValues.bookings.enableMenu,
      }
    : undefined;

  const visitorsInput: AdminFeatureModulesInput | undefined = formValues
    .visitors.enabled
    ? {
        label: formValues.visitors.label,
        type: AdminFeatureModuleType.Visitors,
        enableMenu: formValues.visitors.enableMenu,
      }
    : undefined;

  return [requestsInput, bookingInput, visitorsInput].filter(
    (input): input is AdminFeatureModulesInput => input != null
  );
};

const commissionToInput = (
  commissionPercentage: number | string | null
): number | null => {
  if (commissionPercentage === "" || commissionPercentage == null) {
    return null;
  }
  if (
    typeof commissionPercentage === "string" &&
    (!stringNotEmpty(commissionPercentage) ||
      isNaN(Number(commissionPercentage)))
  ) {
    return null;
  }

  return toRate(`${commissionPercentage}`);
};

export const useOnSubmit = (data: SiteSettingsQueryData): FormikConfig<SiteConfigFormData>["onSubmit"] => {
  const user = useUser();
  const siteCtxHelper = useSiteContextHelper();
  const config = useConfig();
  const [save] = useUpsertDestinationMutation();
  const [saveArtwork] = useUpdateSwfitconnectArtworkMutation();
  const [upsertStatsProviderConfiguration] = useUpsertStatsProviderConfigurationMutation();

  return async (formData) => {
    if (formData.managedBy === "PORTAL") {
      const disallowedChanges = validateChanges(formData, data);
      if (disallowedChanges.length > 0) {
        await EqMessageWarning({
          title: "Attention",
          text: `[${formData.siteName}] is not currently managed by Admin Panel. Select the checkbox ‘Managed by Admin Panel’ in the ‘General Settings’ > ‘Operational Details’ section, in order to save changes to the settings.`,
        });
        return;
      }
    }

    const buildingInfoCategories = formData.buildingInfoCategories
      .filter((c) => c.name.length > 0)
      .map((c, i) => ({
        name: c.name,
        uuid: c.uuid,
        weight: i,
      }));

    const lowercaseCategoryNames = buildingInfoCategories.map((c) =>
      c.name.trim().toLowerCase()
    );

    // Make sure name are unique.
    const uniqueCategories = new Set(lowercaseCategoryNames);
    if (uniqueCategories.size !== buildingInfoCategories.length) {
      await EqMessageWarning({
        title: "Attention",
        text: "Building info subpage name must be unique.",
      });
      return;
    }

    if (
      lowercaseCategoryNames.some(
        (n) => n.length > BUILDINGINFO_CATEGORY_MAX_CHAR
      )
    ) {
      await EqMessageWarning({
        title: "Attention",
        text: `Building info subpage Name can't be more than ${BUILDINGINFO_CATEGORY_MAX_CHAR} characters`,
      });
      return;
    }

    const [artwork, statsProviderConfiguration, result] = await Promise.all([
      saveArtwork({
        variables: { site: data.destination.uuid, url: formData.swiftConnectArtwork },
      }),
      formData.statsProviderConfiguration && upsertStatsProviderConfiguration({
        variables: {
          input: {
            siteUuid: data.destination.uuid,
            enabled: formData.statsProviderConfiguration?.enabled,
            url: formData.statsProviderConfiguration?.url,
          }
        }
      }),
      save({
        refetchQueries: [
          {
            query: SiteDetailsDocument,
            variables: { uuid: data.destination.uuid },
          },
          {
            query: SiteListDocument,
            variables: { installation: config.installation, name: "" },
          },
        ],
        variables: {
          domainInput:
            formData.managedBy !== "PORTAL"
              ? {
                  accountDomain: formData.infrastructure?.accountDomain?.hostname,
                  fromEmailAddress:
                    formData.fromEmail !== defaultFromEmail
                      ? formData.fromEmail
                      : undefined,
                  replyToEmailAddress: stringNotEmpty(formData.replyToEmail)
                    ? formData.replyToEmail
                    : undefined,
                  referenceDomainIsPrimary:
                    formData.infrastructure?.referenceDomainIsPrimary,
                  webDomains:
                    formData.infrastructure?.webDomains.map((wd) => ({
                      domain: wd.domain.hostname,
                      primary: wd.primary,
                      redirect: wd.redirect,
                    })) ?? [],
                  destination: data.destination.uuid,
                }
              : undefined,
          input: {
            uuid: data.destination.uuid,
            status: formData.siteStatus,
            client: formData.client,
            managedByUCM: formData.managedBy !== "PORTAL",
            migratingToUCM: formData.managedBy === "MIGRATING",
            storeEnabled: formData.storeEnabled,
            storeWebEnabled: formData.storeWebEnabled,
            marketplaceTitle: formData.marketplaceTitle,
            name: formData.siteName,
            address: formData.addressInput.address,
            streetName: formData.addressInput.streetName,
            postcode: formData.addressInput.postcode,
            city: formData.addressInput.city,
            state: formData.addressInput.state,
            country: formData.addressInput.country,
            timezone: formData.addressInput.timezone,
            latitude: formData.addressInput.latitude,
            longitude: formData.addressInput.longitude,
            tierLevel: formData.tierLevel,
            locale: formData.locale,
            email: trimmedOrNull(formData.email),
            defaultIrisAuthor: formData.defaultIrisAuthor?.value,
            defaultIrisAssignee: formData.defaultIrisAssignee?.value,
            hiddenStatusMessage: "",
            socialLinks:
              formData.socialLinks as unknown as AdminSocialLinkInput[],
            appHeroImage: formData.appHeroImage,
            buildingInfoHeaderImage: formData.buildingInfoHeaderImage,
            buildingInfoTitle: formData.buildingInfoTitle,
            buildingInfoCategories,
            androidAppId: formData.androidAppId,
            iosAppId: formData.iosAppId,
            iosAppBuildId: formData.iosAppBuildId,
            newsAndEventsEnabled: !formData.newsAndEventsDisabled,
            autoDeactivateUsers: formData.autoDeactivateUsers,
            autoDeactivate3MonthReminder: formData.autoDeactivate3MonthReminder,
            autoDeactivate6MonthReminder: formData.autoDeactivate6MonthReminder,
            autoDeactivate9MonthReminder: formData.autoDeactivate9MonthReminder,
            customStylesheet: formData.webCustomStylesheet,
            webWelcomePageImage: formData.webWelcomePageImage,
            welcomePageHeading: formData.welcomePageHeading,
            welcomePageSubHeading: formData.welcomePageSubHeading,
            webHomePageHeaderImage: formData.webHomePageHeaderImage,
            webBuildingPageHeaderImage: formData.webBuildingPageHeaderImage,
            webSiteLogoImage: formData.webSiteLogoImage,
            webSiteLogoImageSize: formData.webSiteLogoImageSize,
            webFooterLogoImage: formData.webFooterLogoImage,
            webBrowserIconImage: formData.webBrowserIconImage,
            emailHeaderImage: formData.emailHeaderImage,
            emailFooterImage: formData.emailFooterImage,
            showLogoOnWhiteBackground: formData.showLogoOnWhiteBackground,
            textOverAppHeroImage: formData.textOverAppHeroImage,
            textOverHomePageImage: formData.textOverHomePageImage,
            textOverBuildingPageImage: formData.textOverBuildingPageImage,
            primaryColor: formData.primaryColor,
            mobileColor: formData.mobileColor,
            liveChatPK: formData.liveChatPK,
            thirdPartyServiceProviders: formData.thirdPartyServiceProviders,
            newsletterFooter: formData.newsletterFooter,
            thirdPartyCookies: formData.thirdPartyCookies,
            enableReporting: formData.enableReporting,
            emailVerificationRequired: formData.emailVerificationRequired,
            commercialSignupEnabled: formData.commercialSignupEnabled,
            commercialSignupCTA: formData.commercialSignupCTA,
            visitorSignupEnabled: formData.visitorSignupEnabled,
            visitorSignupCTA: formData.visitorSignupCTA,
            visitorCanSubscribeToEmails: formData.visitorCanSubscribeToEmails,
            visitorCanSubscribeToNotifications:
              formData.visitorCanSubscribeToNotifications,
            residentialSignupEnabled: formData.residentialSignupEnabled,
            residentialSignupCTA: formData.residentialSignupCTA,
            parkingProvider: formData.parkingProvider,
            featureModules: featureModuleInput(
              formData.featureModules,
              user.isAdminOrRegionalManager
            ),
            bookingModuleSettings: {
              commissionPercentage: commissionToInput(
                formData.bookingModuleSettings.commissionPercentage
              ),
              yardiEnabled: formData.bookingModuleSettings.yardiEnabled,
            },
            accessControlSettings: formData.accessControlSettings,
          },
        },
      }),
    ]);

    if (artwork.data?.swiftconnect?.updateArtwork !== true) {
      EqMessageError({
        text: "Unable to save SwiftConnect access pass artwork.",
      });
    }

    if (formData.statsProviderConfiguration != null && statsProviderConfiguration?.errors != null) {
      EqMessageError({
        text: "Unable to save StatsProviderConfiguration.",
      });
    }

    if (result.data?.adminUpsertDestination?.__typename === "SuccessResponse") {
      EqMessageSuccess({
        text: "Successfully saved site details.",
      });
    } else if (
      result.data?.adminUpsertDestination?.__typename === "FailureResponse"
    ) {
      EqMessageError({
        text: result.data.adminUpsertDestination.reason,
      });
    } else {
      EqMessageError({
        text: "An unexpected error occurred",
      });
    }

    siteCtxHelper.goBack();
  };
};

export function transformInitialValues(data: SiteSettingsQueryData): SiteConfigFormData {
  const isFeatureEnabled = (type: FeatureModuleType) =>
    data.destination.featureModules.find((fm) => fm.type === type) != null;

  const isFeatureModuleMenuEnabled = (type: FeatureModuleType) => {
    const featureModule = data.destination.featureModules.find((fm) => fm.type === type);
    const featureModuleMenuEnabled =
      featureModule?.enableMenu != null ? featureModule.enableMenu : true;
    return featureModule == null ? false : featureModuleMenuEnabled;
  };

  return {
    type: data.destination.type,
    managedBy:
      data.destination.managedByUCM && data.destination.migratingToUCM
        ? "MIGRATING"
        : data.destination.managedByUCM && !data.destination.migratingToUCM
        ? "UCM"
        : "PORTAL",
    storeEnabled: data.destination.settings.store.enabled,
    storeWebEnabled: data.destination.settings.store.webEnabled,
    marketplaceTitle: data.destination.settings.store.title ?? "Store",
    siteStatus: data.destination.status as unknown as AdminDestinationStatus,
    tierLevel: data.destination.tierLevel,
    client: data.destination.client?.uuid,
    infrastructure: data.destination.infrastructure ?? undefined,
    siteName: data.destination.name,
    enableReporting: data.destination.reportingEnabled,
    email: data.destination.email ?? undefined,
    fromEmail: data.destination.fromEmailAddress ?? defaultFromEmail,
    fromEmailActive:
      data.destination.fromEmailAddress ===
      data.destination.activeFromEmailAddress,
    replyToEmail: data.destination.replyToEmailAddress ?? undefined,
    defaultIrisAuthor:
      data.destination.defaultIrisAuthor?.profile != null
        ? {
            value: data.destination.defaultIrisAuthor.profile.uuid,
            label: data.destination.defaultIrisAuthor.profile.email,
          }
        : undefined,
    defaultIrisAssignee:
      data.destination.defaultIrisAssignee?.profile != null
        ? {
            value: data.destination.defaultIrisAssignee.profile.uuid,
            label: data.destination.defaultIrisAssignee.profile.email,
          }
        : undefined,
    parkingProvider: data.destination.settings.parking
      .parkingProvider as unknown as AdminParkingProvider,
    socialLinkInput: { value: "", type: "" },
    socialLinks:
      data.destination.socialLinks.map((sl) => ({
        value: sl.value,
        type: sl.type,
      })) ?? [],
    buildingInfoHeaderImage: data.destination.settings.buildingInfo.image,
    swiftConnectArtwork: data.destination.integrations?.swiftconnect.artWork,
    buildingInfoTitle: data.destination.settings.buildingInfo.title ?? "",
    buildingInfoCategories: data.destination.settings.buildingInfo.categories,
    buildingInfoCategoryNameInput: "",
    androidAppId: data.destination.androidAppId,
    iosAppId: data.destination.iosAppId,
    iosAppBuildId: data.destination.iosAppBuildId,
    newsAndEventsDisabled: !data.destination.settings.newsAndEvents.enabled,
    appHeroImage: data.destination.settings.branding.appHeroImage,
    webCustomStylesheet:
      data.destination.settings.branding.web.customStylesheet,
    webWelcomePageImage:
      data.destination.settings.branding.web.welcomePageImage,
    webHomePageHeaderImage:
      data.destination.settings.branding.web.homePageHeaderImage,
    webBuildingPageHeaderImage:
      data.destination.settings.branding.web.buildingPageHeaderImage,
    webSiteLogoImage: data.destination.settings.branding.web.siteLogoImage,
    webSiteLogoImageSize:
      data.destination.settings.branding.web.siteLogoImageSize,
    webFooterLogoImage: data.destination.settings.branding.web.footerLogoImage,
    webBrowserIconImage:
      data.destination.settings.branding.web.browserIconImage,
    emailHeaderImage: data.destination.settings.branding.email.headerImage,
    emailFooterImage: data.destination.settings.branding.email.footerImage,
    showLogoOnWhiteBackground:
      data.destination.settings.branding.web.showLogoOnWhiteBackground,
    textOverAppHeroImage:
      data.destination.settings.branding.textOverAppHeroImage,
    textOverHomePageImage:
      data.destination.settings.branding.web.textOverHomePageImage,
    textOverBuildingPageImage:
      data.destination.settings.branding.web.textOverBuildingPageImage,
    autoDeactivateUsers: data.destination.autoDeactivateUsers,
    welcomePageHeading:
      data.destination.settings.branding.web.welcomePageHeading,
    welcomePageSubHeading:
      data.destination.settings.branding.web.welcomePageSubHeading,
    autoDeactivate3MonthReminder: data.destination.autoDeactivate3MonthReminder,
    autoDeactivate6MonthReminder: data.destination.autoDeactivate6MonthReminder,
    autoDeactivate9MonthReminder: data.destination.autoDeactivate9MonthReminder,
    primaryColor: data.destination.settings.branding.primaryColour,
    mobileColor: data.destination.settings.branding.mobileColour,
    liveChatPK: data.destination.settings.liveChatPK,
    newsletterFooter: data.destination.newsletterFooter,
    thirdPartyServiceProviders: data.destination.thirdPartyServiceProviders,
    thirdPartyCookies: data.destination.thirdPartyCookies.map((c) => ({
      type: c.type,
      name: c.name,
      whyItsUsed: c.whyItsUsed,
      expiryPolicy: c.expiryPolicy,
    })),
    addressInput: {
      address: data.destination.address,
      streetName: data.destination.streetName ?? "",
      postcode: data.destination.postcode ?? "",
      city: data.destination.city ?? "",
      state: data.destination.state ?? "",
      country: data.destination.country ?? "",
      timezone: data.destination.timezone,
      latitude: data.destination.latitude,
      longitude: data.destination.longitude,
    },
    locale: data.destination.locale ?? "en",
    emailVerificationRequired:
      data.destination.settings.registration.emailVerificationRequired,
    commercialSignupEnabled:
      data.destination.settings.registration.commercialSignupEnabled,
    commercialSignupCTA:
      data.destination.settings.registration.commercialSignupCTA,
    visitorSignupEnabled:
      data.destination.settings.registration.visitorSignupEnabled,
    visitorSignupCTA: data.destination.settings.registration.visitorSignupCTA,
    visitorCanSubscribeToEmails:
      data.destination.settings.registration.visitorCanSubscribeToEmails,
    visitorCanSubscribeToNotifications:
      data.destination.settings.registration.visitorCanSubscribeToNotifications,
    residentialSignupEnabled:
      data.destination.settings.registration.residentialSignupEnabled,
    residentialSignupCTA:
      data.destination.settings.registration.residentialSignupCTA,
    destinationUuid: data.destination.uuid,
    featureModules: {
      requests: {
        type: AdminFeatureModuleType.Requests,
        enabled: isFeatureEnabled(FeatureModuleType.Requests),
        label: "Requests",
        enableMenu: isFeatureModuleMenuEnabled(FeatureModuleType.Requests),
      },
      bookings: {
        type: AdminFeatureModuleType.Bookings,
        enabled: isFeatureEnabled(FeatureModuleType.Bookings),
        // Not configurable at this point.
        label: "Bookings",
        enableMenu: isFeatureModuleMenuEnabled(FeatureModuleType.Bookings),
      },
      visitors: {
        type: AdminFeatureModuleType.Visitors,
        enabled: isFeatureEnabled(FeatureModuleType.Visitors),
        label: "Visitor appointments",
        enableMenu: isFeatureModuleMenuEnabled(FeatureModuleType.Visitors),
      },
    },
    bookingModuleSettings: {
      commissionPercentage: toPercentageString(
        data.destination.settings.booking.commissionPercentage
      ),
      yardiEnabled: data.destination.settings.booking.yardiEnabled,
    },
    statsProviderConfiguration: data.statsProviderConfiguration && {
      enabled: data.statsProviderConfiguration.enabled,
      url: data.statsProviderConfiguration.url,
    },
    accessControlSettings:
      data.destination.settings.accessControl == null
        ? undefined
        : {
            solution: data.destination.settings.accessControl
              .solution as string as AdminAccessControlSolution,
          },
  };
}
