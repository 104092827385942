import React, { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  SiteDetailsQuery,
  useUpdateDestinationIntegrationsMutation,
} from "../../../../generated/admin";
import { ViciniteeConnectForm } from "./ViciniteeConnectForm";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../message/EqMessage";

export const ViciniteeConnect: React.FC<{
  dest: NonNullable<SiteDetailsQuery>["destination"];
}> = ({ dest }) => {
  const [mutation, { loading }] = useUpdateDestinationIntegrationsMutation({
    refetchQueries: ["SiteDetails"],
  });
  const [displayEditForm, setDisplayEditForm] = useState(false);
  const vicinitee = dest.integrations?.vicinitee;

  const handleDisable = useCallback(async () => {
    const confirm = await EqDangerMessage({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Disable",
      title: "Are you sure?",
      html: "Please confirm that you wish to disable Vicinitee Access Control.",
    });
    if (confirm.value !== true || loading) {
      return;
    }

    try {
      const result = await mutation({
        variables: {
          integrations: {
            destinationUuid: dest.uuid,
            vicinitee: { enabled: false },
          },
        },
      });

      if (!result.data?.updateDestinationIntegrations.success) {
        throw new Error(
          result.data?.updateDestinationIntegrations.message ??
            "Unknown error response from server."
        );
      }

      EqMessageSuccess({ text: "Disabled Vicinitee Access Control." });
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [loading, mutation, dest.uuid]);

  return (
    <div className="pt-2 vicinitee">
      <div className="border p-2 p-md-4 mb-2">
        {vicinitee?.enabled === true && vicinitee.info != null ? (
          <>
            <div className="d-flex justify-content-between">
              <div>
                <strong>Label:</strong> {vicinitee.info.label}
              </div>
              <div className="d-flex justify-content-end align-items-baseline">
                <Button
                  name="disable"
                  variant="danger"
                  onClick={handleDisable}
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Disable"
                  )}
                </Button>
                <Button
                  variant="outline-primary"
                  className="ml-2"
                  onClick={() => setDisplayEditForm(true)}
                  disabled={displayEditForm}
                >
                  Edit
                </Button>
              </div>
            </div>
            {displayEditForm ? (
              <ViciniteeConnectForm
                edit
                label={vicinitee.info.label}
                siteUuid={dest.uuid}
              />
            ) : null}
          </>
        ) : (
          <ViciniteeConnectForm siteUuid={dest.uuid} />
        )}
      </div>
    </div>
  );
};
