import {
  AdminDestinationStatus,
  SocialLink,
  CookieInput,
  SiteDetailsQuery,
  AdminParkingProvider,
  DestinationTierLevel,
  AdminFeatureModuleType,
  AdminAccessControlSettingsInput,
} from "../../../generated/admin";
import { OptionType } from "../../../model/OptionType";
import type { AddressInfo } from "../../../util/address";

export const BUILDINGINFO_CATEGORY_MAX_CHAR = 20;

export interface FeatureModuleType {
  type: AdminFeatureModuleType;
  enabled: boolean;
  label: string;
  enableMenu: boolean;
}

export interface SiteConfigFormData {
  type: SiteDetailsQuery["destination"]["type"];
  destinationUuid: SiteDetailsQuery["destination"]["uuid"];
  siteStatus: AdminDestinationStatus;
  client?: string;
  defaultIrisAuthor?: OptionType;
  defaultIrisAssignee?: OptionType;
  siteName: string;
  tierLevel?: DestinationTierLevel | null;
  managedBy: "PORTAL" | "MIGRATING" | "UCM";
  storeWebEnabled: boolean;
  storeEnabled: boolean;
  marketplaceTitle: string;
  enableReporting?: boolean;
  email?: string;
  socialLinks: Array<SocialLink>;
  socialLinkInput: { value: string; type: string };
  buildingInfoHeaderImage?: string | null;
  swiftConnectArtwork?: string | null;
  appHeroImage?: string | null;
  buildingInfoTitle?: string | null;
  buildingInfoCategories: SiteDetailsQuery["destination"]["settings"]["buildingInfo"]["categories"];
  buildingInfoCategoryNameInput: string;
  androidAppId?: string | null;
  iosAppId?: string | null;
  iosAppBuildId?: string | null;
  thirdPartyCookies: CookieInput[];
  thirdPartyCookieInput?: CookieInput;
  webCustomStylesheet?: string | null;
  webWelcomePageImage?: string | null;
  webHomePageHeaderImage?: string | null;
  webBuildingPageHeaderImage?: string | null;
  webSiteLogoImage?: string | null;
  webSiteLogoImageSize?: string | null;
  webFooterLogoImage?: string | null;
  webBrowserIconImage?: string | null;
  emailHeaderImage?: string | null;
  emailFooterImage?: string | null;
  showLogoOnWhiteBackground?: boolean | null;
  fromEmail?: string | null;
  fromEmailActive: boolean;
  replyToEmail?: string | null;
  primaryColor?: string | null;
  mobileColor?: string | null;
  newsAndEventsDisabled?: boolean;
  textOverAppHeroImage?: boolean;
  textOverHomePageImage?: boolean;
  textOverBuildingPageImage?: boolean;
  termsAndConditionsChangeReason?: string | null;
  privacyPolicyChangeReason?: string | null;
  cookiePolicyChangeReason?: string | null;
  thirdPartyServiceProviders?: string | null;
  newsletterFooter?: string | null;
  liveChatPK?: string | null;
  addressInput: AddressInfo;
  locale: string;
  infrastructure?: SiteDetailsQuery["destination"]["infrastructure"];
  addWebDomain?: string;
  autoDeactivateUsers?: boolean | null;
  autoDeactivate3MonthReminder?: boolean | null;
  autoDeactivate6MonthReminder?: boolean | null;
  autoDeactivate9MonthReminder?: boolean | null;
  welcomePageHeading?: string | null;
  welcomePageSubHeading?: string | null;
  emailVerificationRequired: boolean;
  commercialSignupEnabled: boolean;
  commercialSignupCTA?: string | null;
  visitorSignupEnabled: boolean;
  visitorSignupCTA?: string | null;
  visitorCanSubscribeToEmails: boolean;
  visitorCanSubscribeToNotifications: boolean;
  residentialSignupEnabled: boolean;
  residentialSignupCTA?: string | null;
  parkingProvider?: AdminParkingProvider;
  featureModules?: {
    requests: FeatureModuleType;
    bookings: FeatureModuleType;
    visitors: FeatureModuleType;
  } | null;
  bookingModuleSettings: {
    commissionPercentage: string;
    yardiEnabled: boolean;
  };
  statsProviderConfiguration?: {
    enabled: boolean;
    url?: string | null;
  } | null;
  accessControlSettings?: AdminAccessControlSettingsInput;
}
