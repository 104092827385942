import React, { useState, useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  SiteDetailsQuery,
  usePatchOpenpathMutation,
} from "../../../../generated/admin";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { stringIsEmpty } from "../../../../util/stringIsEmpty";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
  successCb: (email: string, connectedTs: number, label: string) => void;
  label: string;
}

interface FormValue {
  invalid: boolean;
  value: string;
}

interface ConnectForm {
  label: FormValue;
}

export const OpenpathConnectFormEdit: React.FC<Props> = ({
  dest,
  successCb,
  label,
}) => {
  const [mutation, { loading }] = usePatchOpenpathMutation();
  const [state, setState] = useState<ConnectForm>({
    label: { invalid: false, value: label },
  });

  const patch = useCallback(async () => {
    if (state.label.invalid || stringIsEmpty(state.label.value) || loading) {
      return;
    }

    try {
      const result = await mutation({
        variables: {
          site: dest.uuid,
          label: state.label.value.trim(),
        },
      });

      if (result.data?.openpath?.patch == null) {
        throw new Error("Connection returning no data.");
      }

      if (
        result.data.openpath.patch.__typename ===
        "OpenpathAuthenticationFailure"
      ) {
        throw new Error(result.data.openpath.patch.reason);
      }

      const info = result.data.openpath.patch.info;
      EqMessageSuccess({
        text: `Successfully updated ${info.label}.`,
      });
      setState({
        label: { invalid: false, value: info.label },
      });
      successCb(info.email, info.connectedTimestamp, info.label);
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [state, mutation, dest.uuid, successCb, loading]);

  return (
    <div>
      <Form.Group className="pt-4" as={Row}>
        <Form.Label column md="3" lg="2">
          Label
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="openpathLabel"
            type="text"
            isInvalid={state.label.invalid || stringIsEmpty(state.label.value)}
            disabled={loading}
            onChange={(e) =>
              setState((connectForm) => ({
                ...connectForm,
                label: {
                  value: e.target.value,
                  invalid: e.target.value.length > 20,
                },
              }))
            }
            defaultValue={state.label.value}
          />
          <Form.Text className="text-muted">
            Integration name used as label in the mobile app (up to 20
            characters long)
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            {stringIsEmpty(state.label.value)
              ? "The label is missing."
              : "Label must be 20 characters or less"}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <div className="text-right">
        <Button
          name="openpathPatch"
          variant="outline-primary"
          onClick={patch}
          disabled={
            loading || state.label.invalid || stringIsEmpty(state.label.value)
          }
        >
          {loading ? (
            <span>
              <Spinner size="sm" animation="grow" /> Loading...
            </span>
          ) : (
            "Update Openpath Connection"
          )}
        </Button>
      </div>
    </div>
  );
};
